import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaperRoot: {
      width: "100vw",
      maxWidth: "100vw",
      height: "100vh",
      maxHeight: "100vh",
      boxShadow: "0px 4px 4px 0px #00000040",
      margin: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "12px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: 0,
      borderRadius: 0
    },
    dialogWinnerHeading: {
      fontSize: "40px",
      color: theme.palette.secondary.dark,
      textAlign: "center",
      margin: 0
    },
    dialogWinnerLabelDesc: {
      fontSize: "21px",
      color: theme.palette.secondary.main,
      textAlign: "center",
      margin: 0,
      fontFamily: "VodafoneRgBold",
      marginTop: "4rem",
      width: "75%"
    },
    dialogWinnerDisaclaimer: {
      fontSize: "20px",
      color: theme.palette.secondary.dark,
      textAlign: "center",
      margin: 0,
      padding: "0 16px"
    },
    cliamNowButton: {
      borderRadius: "12px",
      padding: "4px 12px",
      color: "#d31215",
      "& span": {
        textTransform: "none",
        fontSize: "18px",
        fontWeight: "bold"
      }
    },
    dialogMidContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      alignItems: "center",
      width: "100%"
    },
    titleText: {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "30px",
      letterSpacing: "-0.063px",
      textAlign: "center",
      color: theme.palette.common.white
    },
    shortTitleText: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "-0.063px",
      textAlign: "center",
      color: theme.palette.common.white
    },
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "88.331vw",
      "& p": {
        margin: 0
      }
    },
    spinWinMainContainer: {
      height: "calc(100vh - 45px)",
      width: "100vw",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: "6vh",
      padding: "20px",
      overflowX: "hidden",
      backgroundPosition: "center"
    },
    wheelWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100vw",
      height: "100%",
    }
  })
);
