import {
  array,
  number,
  object,
  oneOf,
  string,
  boolean,
  iso8601,
  unknown,
  guard,
  nullable,
  predicate,
  compose,
  either,
  optional,
  maybe
} from "decoders";
import { getClientName } from "Utils/GeneralUtils";
import { SchemaModel } from "Configurations/ModelMappings";
const nonEmptyArray = decoder => {
  return compose(
    array(decoder),
    predicate(arr => arr.length > 0, "Must be non-empty array")
  );
};

const image = object({
  imageUrl: string,
  classifier: oneOf([
    "OFFER_IMAGE_LARGE",
    "OFFER_IMAGE_MEDIUM",
    "OFFER_IMAGE_SMALL"
  ])
});

const redemptionDetail = object({
  method: oneOf([
    "AUTOMATIC",
    "CALL",
    "INSTORE",
    "INSTORE_OR_PRINT",
    "PRINT",
    "LINK",
    "ONLINE",
    "INSTORE_PRINT",
    "INSTORE_OR_ONLINE"
  ]),
  type: oneOf([
    "ALPHANUMERIC",
    "BARCODE_CODE_128",
    "BARCODE_EAN_128",
    "BARCODE_EAN_13",
    "BARCODE_EAN_8",
    "BARCODE_GC_19",
    "NONE",
    "QR",
    "URL"
  ]),
  url: nullable(string),
  guidelines: nullable(string),
  prizeName: nullable(string),
  captureEmail: nullable(boolean)
});

const merchant = object({
  id: number,
  name: string,
  imageUrl: string
});

const bannerClaimsLimitReach = object({
  total: nullable(boolean),
  notEnoughCodes: nullable(boolean),
  totalPerPeriod: nullable(boolean),
  user: nullable(boolean),
  userPerPeriod: nullable(string)
});

const claimsLimitReach = object({
  total: nullable(boolean),
  notEnoughCodes: nullable(boolean),
  totalPerPeriod: nullable(boolean),
  user: nullable(boolean),
  userPerPeriod: nullable(string)
});

const recordType = object({
  key: string,
  value: either(string, boolean)
});

const claimConditionsFailed = object({
  dimension: string,
  subDimension: string,
  values: array(string)
});

const comingSoon = object({
  title: nullable(string),
  images: nullable(array(image)),
  shortTitle: nullable(string)
});

const offerPrizeDetail = object({
  prizeName: nullable(string),
  voucherCodeExpiryModelType: nullable(string),
  voucherCodePresentation: nullable(string),
  voucherCodeExpiryModelValue: nullable(string)
});

const OfferRatingDetails = object({
  id: nullable(number),
  feedback: nullable(string),
  offerId: nullable(number),
  customerId: nullable(string),
  offerRating: nullable(number),
  createdOn: nullable(string)
});

const offerTypeOther = object({
  id: number,
  title: string,
  titleShort: string,
  description: string,
  offerType: oneOf([
    "ARTICLE",
    "GOODWILLREWARD",
    "PRIZEDRAW",
    "REWARD",
    "INSTANT_WIN",
    "LUCKY_DIP",
    "VISIT_TO_WIN",
    "SPIN_TO_WIN"
  ]),
  termsAndConditions: nullable(string),
  termsAndConditionsUrl: nullable(string),
  preClaimAdvice: string,
  teaser: nullable(string),
  carouselCardTextColor: string,
  keyTerms: nullable(string),
  entryMechanics: nullable(string),
  images: array(image),
  redemptionDetails: array(redemptionDetail),
  merchant: merchant,
  startDateTime: iso8601,
  endDateTime: iso8601,
  claimLimitsReached: claimsLimitReach,
  claimConditionsFailed: nullable(array(claimConditionsFailed)),
  ctaDetails: array(recordType),
  additionalDetails: array(recordType),
  shareable: maybe(boolean),
  showPreClaimPopup: boolean,
  voucherCodeExpiryModelType: oneOf(["None", "DateTime", "Timer"]),
  weight: number,
  comingSoonDetails: maybe(comingSoon),
  giveToLiteUser: maybe(boolean),
  offerPrizeDetails: nullable(array(offerPrizeDetail)),
  isStoreGiftingOffer: nullable(boolean),
  isConvergedOffer: nullable(boolean),
  isQRActive: maybe(boolean),
  offerRating: nullable(OfferRatingDetails),
  isReferralReward: maybe(boolean)
});

const offerTypeBanner = object({
  id: number,
  title: string,
  titleShort: nullable(string),
  description: string,
  offerType: oneOf(["BANNER"]),
  termsAndConditions: nullable(string),
  termsAndConditionsUrl: nullable(string),
  preClaimAdvice: string,
  teaser: nullable(string),
  carouselCardTextColor: string,
  keyTerms: nullable(string),
  entryMechanics: nullable(string),
  images: array(image),
  redemptionDetails: array(redemptionDetail),
  merchant: merchant,
  startDateTime: nullable(iso8601),
  endDateTime: nullable(iso8601),
  claimLimitsReached: claimsLimitReach,
  claimConditionsFailed: nullable(array(claimConditionsFailed)),
  ctaDetails: array(recordType),
  additionalDetails: array(recordType),
  shareable: maybe(boolean),
  showPreClaimPopup: nullable(boolean),
  voucherCodeExpiryModelType: oneOf(["None", "DateTime", "Timer", " ", ""]),
  weight: number,
  comingSoonDetails: optional(comingSoon),
  giveToLiteUser: maybe(boolean),
  offerPrizeDetails: nullable(array(offerPrizeDetail)),
  isStoreGiftingOffer: nullable(boolean),
  isConvergedOffer: nullable(boolean),
  isQRActive: maybe(boolean),
  isReferralReward: maybe(boolean)
});

const offer = either(offerTypeBanner, offerTypeOther);

const pagingInfo = object({
  pageNumber: number,
  pageSize: number,
  totalResults: number
});

const displayCategory = object({
  id: number,
  name: string,
  displayPosition: number,
  imageUrl: string,
  pagingInfo: nullable(pagingInfo),
  offerIds: array(number)
});

const favourites = object({
  pagingInfo: pagingInfo,
  //favourites: array(favourite)
  favourites: array(SchemaModel[getClientName()].favourite)
});

const claims = object({
  id: number,
  offerId: number,
  memberId: maybe(string),
  offerType: oneOf([
    "ARTICLE",
    "GOODWILLREWARD",
    "PRIZEDRAW",
    "REWARD",
    "INSTANT_WIN",
    "BANNER",
    "LUCKY_DIP",
    "VISIT_TO_WIN",
    "SPIN_TO_WIN"
  ]),
  redemptionCode: nullable(string),
  expiryDateTime: nullable(iso8601),
  claimDateTime: iso8601,
  byAgent: unknown,
  attributes: unknown,
  source: nullable(string),
  prizeName: nullable(string),
  prizeType: nullable(string)
});

const claimedOffers = object({
  pagingInfo: pagingInfo,
  claims: array(claims)
});
const collectionCardImage = object({
  imageUrl: string,
  altText: nullable(string),
  classifier: string
});
const collectionCardDetails = object({
  id: number,
  images: array(collectionCardImage),
  offerIds: array(number),
  showOverlayText: nullable(boolean),
  subTitle: string,
  textColor: string,
  title: string
});

const carousel = object({
  offerId: nullable(number),
  label: string,
  collectionCard: maybe(collectionCardDetails)
});

const outlet = object({
  businessHours: array(string),
  description: string,
  id: number,
  isActive: boolean,
  latitude: number,
  numberitude: number,
  merchantId: number,
  name: string,
  offerIds: array(number),
  phoneNumber: string
});

const profilePermission = object({
  sms: boolean,
  call: boolean,
  email: boolean,
  profiling: boolean,
  traffic: boolean,
  location: boolean
});

const interest = object({
  id: number,
  name: string
});

const profile = object({
  firstUse: boolean,
  interests: array(interest),
  permissions: profilePermission,
  dob: nullable(string)
});

const configurationDataList = object({
  id: string,
  name: string,
  value: nullable(string),
  type: nullable(string),
  lastUpdated: nullable(iso8601)
});

const appSectionList = object({
  id: string,
  name: string,
  configurationDataList: array(configurationDataList),
  lastUpdated: nullable(iso8601),
  description: nullable(string)
});

const appConfig = object({
  tenantId: number,
  appName: string,
  appSectionList: array(appSectionList),
  lastUpdated: nullable(iso8601),
  interests: array(interest)
});

const payGTrial = object({
  "Coffee Club": optional(string),
  "Eat Local": optional(string),
  chatToken: optional(string),
  currentDateTime: optional(string),
  appAccessLevel: oneOf(["FULL", "PAYG_LITE", "PAYG_PLUS_LITE"]),
  email: optional(nullable(string)),
  fullVisitToWinPopup: optional(nullable(boolean)),
  halfVisitToWinPopup: optional(nullable(boolean)),
  offerEntries: optional(nullable(number)),
  s2wOfferEntries: optional(nullable(number)),
  isConvergedUser: maybe(boolean),
  customerType: maybe(string),
  lastSeenAt: maybe(string)
});

const announcement = object({
  title: string,
  description: string,
  id: number,
  ctaColor: string,
  ctaText: string,
  backgroundImage: nullable(string),
  ctaRedirect: string,
  entityId: nullable(string),
  iconImage: nullable(string),
  bannerImage: nullable(string),
  showOnEveryVisit: optional(boolean)
});

const referral = object({
  referralLink: nullable(string),
  closedReferrals: nullable(number),
  pendingReferrals: nullable(number),
  selfReferralPending: boolean
});

const schema = object({
  id: string,
  firstName: nullable(string),
  lastName: nullable(string),
  externalMemberId: string,
  offers: nonEmptyArray(offer),
  displayCategories: nonEmptyArray(displayCategory),
  favourites: favourites,
  claimedOffers: claimedOffers,
  carousel: nonEmptyArray(carousel),
  outlets: nullable(array(outlet)),
  profile: profile,
  totalClaimedOffers: number,
  appConfig: appConfig,
  appVersion: string,
  attributes: maybe(payGTrial),
  externalMemberRegistrationId: string,
  announcement: nullable(announcement),
  referrals: nullable(referral)
});

export const payloadGuard = guard(schema);
